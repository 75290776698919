import { initializeApp } from 'firebase/app';
import { Auth, getAuth, inMemoryPersistence, setPersistence } from 'firebase/auth';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import firebaseConfig from 'variables/firebase-config';

const app = initializeApp(firebaseConfig);

class FirebaseApp {
  auth: Auth;
  storage: FirebaseStorage;

  constructor() {
    this.auth = getAuth(app);
    setPersistence(this.auth, inMemoryPersistence);
    this.storage = getStorage(app);
  }

  attachAuthListener(handler) {
    this.auth.onAuthStateChanged(handler);
  }
}

const firebaseApp = new FirebaseApp();

export default firebaseApp;
