/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Switch, withRouter } from 'react-router-dom';
import { message, Typography } from 'antd';

// Routes
import Routes from 'routes';

import firebaseApp from 'stores/firebase-app';
import { Card, ProgressBar } from '@blueprintjs/core';

import AppContainer from 'layouts/AppContainer';
import EmptyLayout from 'layouts/EmptyLayout';

// React hot reloader
import { hot, setConfig } from 'react-hot-loader';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { ILoginToken } from './interfaces/login-token-interface';

const Loading = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Card style={{ width: '25%', textAlign: 'center' }}>
      <div style={{ marginBottom: '16px' }}>
        <Typography.Text style={{ fontSize: '16px' }}>Loading...</Typography.Text>
      </div>
      <ProgressBar animate={true} />
    </Card>
  </div>
);

class App extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setAppLoaded(false);
    firebaseApp.attachAuthListener(this._handleAuthStateChange);

    // ! create store listener
  }

  componentWillMount() {}

  componentWillUnmount = () => {};

  _onClickBadge = () => {
    const { history, doResetNotificationBadgeCount } = this.props;
    doResetNotificationBadgeCount();
    history.push('/notifications');
  };

  _onClickMessage = () => {
    const { history } = this.props;
    history.push('/messaging');
  };

  // Handles the Auth state changes. This is triggered whenever someone logs in / out.
  _handleAuthStateChange = async (state) => {
    const {
      doGetLoginToken,
      doSignOutUsers,
      doFetchPortalUserList,
      setIsUserBlocked,
      setIsWorkerRemoved,
      doUpdatePendingInvitations
    } = this.props;
    let returnTo: string;

    const { location } = this.props;
    const { pathname } = this.props.history.location;

    const data = new URLSearchParams(location.search);

    // returnTo is for zendesk redirect
    returnTo = data.get('return_to');
    const redirectTo = data.get('redirect');
    if (state != null) {
      // firebase authenticated
      if (pathname !== '/register') {
        try {
          let result: ILoginToken;

          const portalUserList = await doFetchPortalUserList();
          const portalUserResult = portalUserList[0];

          if (portalUserResult.status === 'BLOCKED') {
            // if not enabled, block them access anything
            setIsUserBlocked(true);
            doSignOutUsers();
          } else if (portalUserResult.status === 'REMOVED') {
            setIsWorkerRemoved(true);
            doSignOutUsers();
          } else {
            // else can redirect to related page
            if (returnTo) {
              result = await doGetLoginToken({ type: 'zendesk' });
            } else {
              result = await doGetLoginToken({});
            }

            if (_.isEmpty(result)) {
              // not authenticated
              message.error(
                'You are not authorized to login to any service provider. Please contact your administrator.'
              );

              doSignOutUsers();
              this.props.history.push('/login');
            } else if (result.isWorkerRemoved) {
              setIsWorkerRemoved(true);
              doSignOutUsers();
            } else {
              // if returnTo is empty will redirect user to portal
              if (!returnTo) {
                // Add here to auto redirect to dashboard.
                if (pathname === '/' || pathname === '/login') {
                  if (result.isPortalUser) {
                    if (redirectTo) {
                      await doUpdatePendingInvitations(portalUserResult.userId);
                      window.location.href = `${redirectTo}?token=${result.token}`;
                    } else {
                      //TODO: redirect to somewhere else
                    }
                  }
                }
              } else {
                // if return to is not empty will redirect to zendesk for now
                if (result.isPortalUser) {
                  // will check is the user is a portal user or not then redirect
                  if (result.additionalInfo) {
                    window.location.href = CommonUtils.getZendeskRedirectUrl(result, returnTo);
                  }
                }
              }
            }
          }
        } catch (e) {
          console.log(e);
          // not authenticated
          message.error('You are not authorized to login to any service provider. Please contact your administrator.');
          doSignOutUsers();
          this.props.history.push('/login');
        }
      }
    } else {
      // not authenticated
      // prevent push to login if the route is already login, to keep the params inside the url
      if (pathname !== '/login' && pathname !== '/reset' && pathname !== '/register') {
        this.props.history.push('/login');
      }
    }
    this.props.setAppLoaded(true);
  };

  render() {
    const { authStore, badgeCount, messagingBadgeCount } = this.props;

    // Waiting for initialization
    if (!authStore.isAppLoaded) {
      return <Loading />;
    } else {
      const { portalUser } = authStore;

      if (!portalUser) {
        // Default view
        return (
          <Switch>
            <Routes isAuthenticated={false} />
          </Switch>
        );
      } else {
        const { pathname } = this.props.history.location;
        switch (pathname) {
          case '/pdf':
          case '/redirect': {
            return (
              <EmptyLayout>
                <Switch>
                  <Routes isAuthenticated={true} />
                </Switch>
              </EmptyLayout>
            );
          }
          case '/register':
          case '/login':
          case '/reset': {
            return (
              <Switch>
                <Routes isAuthenticated={true} />
              </Switch>
            );
          }
          default: {
            return (
              <AppContainer
                portalUser={portalUser}
                badgeCount={badgeCount}
                messagingBadgeCount={messagingBadgeCount}
                onClickBadge={this._onClickBadge}
                onClickMessage={this._onClickMessage}
              >
                <Switch>
                  <Routes isAuthenticated={true} />
                </Switch>
              </AppContainer>
            );
          }
        }
      }
    }
  }
}

const mapState = (state) => ({
  authStore: state.authStore

  // notifications badge count

  // messages badge count
});

const mapDispatch = (dispatch) => ({
  setAppLoaded: dispatch.authStore.setAppLoaded,
  doSignOutUsers: dispatch.authStore.doSignOutUsers,
  doGetLoginToken: dispatch.authStore.doGetLoginToken,
  doFetchPortalUserList: dispatch.authStore.doFetchPortalUserList,
  setIsUserBlocked: dispatch.authStore.setIsUserBlocked,
  setIsWorkerRemoved: dispatch.authStore.setIsWorkerRemoved,
  doUpdatePendingInvitations: dispatch.authStore.doUpdatePendingInvitations
});

const connectDispatch = connect(
  mapState,
  mapDispatch
);

// @ts-ignore
setConfig({ showReactDomPatchNotification: false });

export default hot(module)(
  compose(
    connectDispatch,
    withRouter
  )(App)
);
