import {
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import firebaseApp from 'stores/firebase-app';
import apiClient from 'utilities/api-client';

const initialState = {
  currentUser: null,
  isAppLoaded: false,
  portalUser: null,
  portalUserList: null,
  invitation: null,
  isUserBlocked: false,
  isWorkerRemoved: false
};

const authStore = {
  state: initialState,
  reducers: {
    setCurrentUser: (state, payload) => ({ ...state, currentUser: payload }),
    setAppLoaded: (state, payload) => ({ ...state, isAppLoaded: payload }),
    setPortalUserList: (state, payload) => ({ ...state, portalUserList: payload }),
    setCurrentPortalUser: (state, payload) => ({ ...state, portalUser: payload }),
    setInvitation: (state, payload) => ({ ...state, invitation: payload }),
    setIsUserBlocked: (state, payload) => ({ ...state, isUserBlocked: payload }),
    setIsWorkerRemoved: (state, payload) => ({ ...state, isWorkerRemoved: payload })
  },
  effects: (dispatch) => ({
    async doSignIn(payload, state) {
      const auth = firebaseApp.auth;
      const { login, password } = payload;
      try {
        const results = await signInWithEmailAndPassword(auth, login, password);

        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          // providerData,
          uid
        } = results.user;

        const currentUser = {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid
        };

        // await dispatch.authStore.setCurrentUser(currentUser);
        return currentUser;
      } catch (err) {
        console.log(err);
        throw Error('invalid account or password');
      }
    },

    async doRegister(payload, state) {
      const auth = firebaseApp.auth;
      const { email, password, firstName, lastName, token, serviceProviderId, mobile } = payload;

      try {
        const results = await createUserWithEmailAndPassword(auth, email, password);

        const {
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          // providerData,
          uid
        } = results.user;

        const mobileCountryCode = 'AU';

        // let finalMobile = mobile;
        // if (mobile.substr(0, 2) === '04') {
        //   finalMobile = `+61${mobile.trim().substr(1, 20)}`;
        // }
        //
        // // check to see if mobile no starts with +. If not, append + to the front.
        // if (finalMobile.substr(0, 1) !== '+') {
        //   finalMobile = '+' + finalMobile;
        // }

        const dbUser = {
          serviceProviderId,
          firstName,
          lastName,
          email,
          token,
          // mobile: finalMobile,
          mobile,
          mobileCountryCode
        };

        await apiClient.post('/api/portal/service-provider/portal-user/new', dbUser);

        // console.log(results);
        // console.log(result);
        //
        // await dispatch.authStore.doFetchPortalUserList();
        //
        // await dispatch.authStore.setCurrentPortalUser(this.state.portalUserList[0]);
        //
        // console.log(this.state.portalUserList);

        const currentUser = {
          displayName: firstName + ' ' + lastName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          uid
        };

        return currentUser;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckValidateToken(payload, state) {
      try {
        const { token } = payload;
        const result = await apiClient.get(`/verify/service-provider/${token}`);
        dispatch.authStore.setInvitation(result.data);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doGetLoginToken(payload, state) {
      const { type } = payload;
      try {
        let fullUrl = `/login`;
        if (type === 'zendesk') {
          fullUrl += '?type=zendesk';
        }

        const result = await apiClient.get(fullUrl);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doSendResetPasswordEmail(payload, state) {
      try {
        const { email } = payload;
        await sendPasswordResetEmail(firebaseApp.auth, email);
        // const result = await apiClient.post('/verify/test-send', { email });
        //
        // return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchPortalUserList(payload, state) {
      try {
        const result = await apiClient.get(`/api/portal/service-provider/portal-user/list`);

        dispatch.authStore.setPortalUserList(result.data);
        dispatch.authStore.setCurrentPortalUser(result.data[0]);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCheckActionToken(payload, state) {
      try {
        const { code } = payload;
        return await checkActionCode(firebaseApp.auth, code);
      } catch (e) {
        throw e;
      }
    },

    async doSetNewPassword(payload, state) {
      try {
        const { code, password } = payload;
        return await confirmPasswordReset(firebaseApp.auth, code, password);
      } catch (e) {
        throw e;
      }
    },

    async doSignOutUsers() {
      await firebaseApp.auth.signOut();
    },

    async doUpdatePendingInvitations(userId) {
      try {
        const result = await apiClient.put(`/api/portal/user/workers/${userId}/update-pending-invitations`);
        return result.data;
      } catch (e) {
        throw e;
      }
    }
  })
};

export default authStore;
