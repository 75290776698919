import { ProgressBar } from '@blueprintjs/core';
import { Form, Button, Col, Icon, Input, message, notification, Row } from 'antd';
// import firebaseApp from 'stores/firebase-app';
import RunningDoodle from 'assets/illustrations/RunningDoodle.png';
import { logoBlack } from 'assets/logo';
import { Text } from 'common-components/typography';
import NotAuthorizedLayout from 'layouts/NotAuthorizedLayout';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import { BlueprintColors } from 'variables/colors';
import { HyperlinkButton } from '../../common-components/buttons';
import CenteredLayout from '../../layouts/CenteredLayout';
import asyncDelay from '../../utilities/asyncDelay';
import * as H from 'history';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  authStore: typeof state.authStore;
  portalUserList: typeof state.authStore.portalUserList;
  history: H.History;
  setCurrentUser: typeof dispatch.authStore.setCurrentUser;
  doSignIn: typeof dispatch.authStore.doSignIn;
  setCurrentPortalUser: typeof dispatch.authStore.setCurrentPortalUser;
  doSendResetPasswordEmail: typeof dispatch.authStore.doSendResetPasswordEmail;
  setIsWorkerRemoved: typeof dispatch.authStore.setIsWorkerRemoved;
  setIsUserBlocked: typeof dispatch.authStore.setIsUserBlocked;
}

interface State {
  email: string;
  isLoggingIn: boolean;
  isForgetPassword: boolean;
  displayLoginView: boolean;
}

class LoginView extends Component<Props, State> {
  state = {
    email: '',
    isLoggingIn: false,
    isForgetPassword: false,
    displayLoginView: false,
  };

  // Simple state handler
  _handleInput = (target, value) => {
    const targetObj = {};
    targetObj[target] = value;
    this.setState(targetObj);
  };

  private _handleLogin = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const dismissMessage = message.loading('Signing in...');
        this.setState({ isLoggingIn: true });

        try {
          const { doSignIn } = this.props;

          await doSignIn(values);

          dismissMessage();
          message.success('Sign in success! Redirecting...');
          this.setState({ isLoggingIn: false });
        } catch (err) {
          dismissMessage();
          this.setState({ isLoggingIn: false });
          message.error(err.message);
        }
      }
    });
  };

  _onClickSend = async () => {
    this.setState({ isLoggingIn: true });
    const { doSendResetPasswordEmail } = this.props;
    const { email } = this.state;
    try {
      await doSendResetPasswordEmail({ email: email });
      notification.success({
        message: 'Reset instructions sent',
        description: 'Please check your email inbox and follow the instructions to reset your password.',
      });
      this.setState({ isLoggingIn: false, isForgetPassword: false });
    } catch (e) {
      // notification.error({
      //   message: 'Error',
      //   description: e.message
      // });
      notification.success({
        message: 'Reset instructions sent',
        description: 'Please check your email inbox and follow the instructions to reset your password.',
      });
      this.setState({ isLoggingIn: false, isForgetPassword: false });
    }
  };

  componentDidMount() {
    const { history, setIsUserBlocked, setIsWorkerRemoved } = this.props;

    const data = new URLSearchParams(history.location.search);
    const returnTo = data.get('return_to');
    const redirectTo = data.get('redirect');
    const isBlocked = data.get('isUserBlocked');
    const isRemoved = data.get('isWorkerRemoved');

    if (isBlocked) {
      setIsUserBlocked(true);
    }
    if (isRemoved) {
      setIsWorkerRemoved(true);
    }

    if (returnTo || redirectTo) {
      const returnTo = data.get('return_to');
      const redirectTo = data.get('redirect');
      if (
        _.includes(returnTo, 'goodhuman.zendesk.com') ||
        _.includes(redirectTo, 'goodhuman.me') ||
        _.includes(redirectTo, 'localhost:3000')
      ) {
        const resetPasswordAction = data.get('action');
        if (resetPasswordAction === 'reset') {
          this.setState({ displayLoginView: true, isForgetPassword: true });
        } else {
          this.setState({ displayLoginView: true });
        }
      }
    } else {
      // TODO: this is the part to control redirect
      asyncDelay(5000).then(() => {
        window.location.href = 'https://www.goodhuman.me';
      });
    }
  }

  render() {
    // const { authStore } = this.props;

    return (
      <NotAuthorizedLayout>
        {/* Login Panel*/}
        {/* NOTE Top Content Section */}
        <CenteredLayout maxWidth={800} height={'100%'} className="flex-column justify-center">
          <div className="flex-row justify-center pt-medium pb-x2-large">
            <img src={logoBlack} alt="" style={{ width: '45%', height: '100%' }} />
          </div>

          {!this.state.displayLoginView && this.noRedirectView()}
          {this.state.displayLoginView && (this.state.isForgetPassword ? this.forgetPasswordView() : this.loginView())}
        </CenteredLayout>
      </NotAuthorizedLayout>
    );
  }

  private noRedirectView = () => {
    return (
      <div className="text-align-center">
        <img src={RunningDoodle} alt="" style={{ width: '35%', height: '100%' }} /> <br />
        <Text size={'x2-large'} weight="black">
          Oops!
        </Text>
        <br />
        <div className="mt-small">
          <Text size="large" color={'secondary'}>
            Sorry, this is only available through a secure link.
          </Text>
          <br />
          <Text size="large" color={'secondary'}>
            You'll be redirected to our main site shortly...
          </Text>
        </div>
      </div>
    );
  };

  private loginView() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className="text-align-center pb-x3-large">
          <Text className="text-size-x2-large">Sign in to manage your services and discover great communities.</Text>
        </div>

        {/* NOTE Input box Section */}
        {/* TODO Validate email & password */}
        <Row type={'flex'} justify={'center'}>
          <Form onSubmit={this._handleLogin} className="login-form">
            <Col md={24}>
              <Form.Item style={{ marginBottom: '-1px'}}>
                {getFieldDecorator('login', {
                  initialValue: '',
                  rules: [{ required: true, message: 'Please input your email.' }],
                })(
                  <Input
                    size={'large'}
                    placeholder={'Enter your email here'}
                    allowClear
                    prefix={<Icon type={'user'} className="text-color-tertiary" />}
                  />,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('password', {
                  initialValue: '',
                  rules: [{ required: true, message: 'Please input your password.' }],
                })(
                  <Input.Password
                    size={'large'}
                    placeholder={'Password'}
                    prefix={<Icon type={'lock'} className="text-color-tertiary" />}
                  />,
                )}
              </Form.Item>

              {this.props?.authStore?.isWorkerRemoved && (
                <div style={{ color: BlueprintColors.RED3 }}>
                  This account has had its access revoked. Please contact your employer if you think this is a mistake.
                </div>
              )}

              {this.props?.authStore?.isUserBlocked && (
                <div style={{ color: BlueprintColors.RED3 }}>You have been blocked from accessing GoodHuman</div>
              )}

              {/* TODO Forgot your password functionality */}
              <div className="text-align-right mv-large mr-small">
                <HyperlinkButton onClick={() => this.setState({ isForgetPassword: true })}>
                  Forgot your password?
                </HyperlinkButton>
              </div>

              <Button
                htmlType="submit"
                type={'primary'}
                block
                size={'large'}
                className={'bg-blue-action border-blue-action'}
                style={{
                  marginBottom: '16px',
                }}
              >
                {/*<Text>Sign In</Text>*/}Sign In
              </Button>

              <Button
                type={'dashed'}
                block
                size={'large'}
                className="hover-border-blue-action"
                onClick={() => (window.location.href = 'https://www.goodhuman.me')}
              >
                <Text className="text-weight-regular">New to GoodHuman? Start here.</Text>
              </Button>

              <div style={{ marginTop: '24px', minHeight: '28px' }}>
                {this.state.isLoggingIn && <ProgressBar animate={true} />}
              </div>

              <div style={{ marginTop: '0px' }} className="text-align-center">
                <Text className="text-size-x-small text-color-secondary">
                  * By logging in, you agree to our Terms of Use and acknowledge that you have read our Privacy
                  Agreement.
                </Text>
              </div>
            </Col>
          </Form>
        </Row>
      </>
    );
  }

  private forgetPasswordView() {
    return (
      <div className="anim-slide-left">
        <div className="pb-medium text-align-center">
          <Text size="x2-large" weight="bold">
            Reset your password
          </Text>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '32px', minHeight: '48px' }}>
          <Text style={{ fontSize: '16px' }}>
            Forgot your password? It happens. <br /> Enter your email address below, and we'll help you get right back
            into <b>GoodHuman</b>!
          </Text>
        </div>

        {/* NOTE Input box Section */}
        {/* TODO Validate email & password */}
        <Row type={'flex'} justify={'center'}>
          <Col md={24}>
            {/* Email Input */}
            <Input
              size={'large'}
              // style={{ marginBottom: -1 }}
              placeholder={'Enter your email here'}
              allowClear
              value={this.state.email}
              onChange={(e) => this._handleInput('email', e.target.value)}
              prefix={<Icon type={'user'} className="text-color-tertiary" />}
              className="mb-medium"
            />

            {/* TODO Forgot your password functionality */}
            <div className="text-align-right mb-large">
              <HyperlinkButton onClick={() => this.setState({ isForgetPassword: false })}>
                Have an account? Sign in here.
              </HyperlinkButton>
            </div>

            <Button
              type={'primary'}
              className={'bg-blue-action border-blue-action'}
              block
              size={'large'}
              style={{
                marginBottom: '16px',
              }}
              onClick={this._onClickSend}
            >
              {/*<Text>Sign In</Text>*/}Send Reset Email
            </Button>

            <div style={{ marginTop: '24px', minHeight: '28px' }}>
              {this.state.isLoggingIn && <ProgressBar animate={true} />}
            </div>

            <div style={{ marginTop: '0px' }} className="text-align-center">
              <Text className="text-size-x-small text-color-secondary">
                * By logging in, you agree to our Terms of Use and acknowledge that you have read our Privacy Agreement.
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state) => ({
  authStore: state.authStore,
  portalUserList: state.authStore.portalUserList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCurrentUser: dispatch.authStore.setCurrentUser,
  doSignIn: dispatch.authStore.doSignIn,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  doSendResetPasswordEmail: dispatch.authStore.doSendResetPasswordEmail,
  setIsWorkerRemoved: dispatch.authStore.setIsWorkerRemoved,
  setIsUserBlocked: dispatch.authStore.setIsUserBlocked,
});

const connectStateDispatch = connect(mapState, mapDispatch);

export default compose(connectStateDispatch)(Form.create<Props>()(LoginView));
