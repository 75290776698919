import React, { Component } from 'react';
import { Button, Col, Form, Icon, Input, Row } from 'antd';
import { logoBlack } from 'assets/logo';
import NotAuthorizedLayout from 'layouts/NotAuthorizedLayout';
import { BlueprintColors } from 'variables/colors';
import { ProgressBar } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import qs from 'query-string';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { FormComponentProps } from 'antd/lib/form';
import CenteredLayout from 'layouts/CenteredLayout';

import { FaLock } from 'react-icons/fa';
import { HyperlinkButton } from 'common-components/buttons';
import globalConfig from '../../variables/global-config';

interface ResetPasswordViewProps extends FormComponentProps {
  doCheckActionToken: typeof dispatch.authStore.doCheckActionToken;
  doSetNewPassword: typeof dispatch.authStore.doSetNewPassword;
  doSignOutUsers: typeof dispatch.authStore.doSignOutUsers;
  history: any;
}

class ResetPasswordView extends Component<ResetPasswordViewProps, any> {
  state = {
    isLoading: false,
    isError: false,
    isResetting: false,
    isValidate: true,
    confirmDirty: false,
    isSuccess: false
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      const password = form.getFieldValue('password');
      let regexp = RegExp('(?=.{8,})');
      regexp.test(password);
      callback('Both passwords must be exactly the same.');
    } else {
      callback();
    }
  };

  validateEnteredPassword = (rule, value, callback) => {
    const { form } = this.props;
    const formValue = form.getFieldValue('password');
    const eightDigitRegExp = RegExp('(?=.{8,})');
    const passwordStrengthRegExp = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
    // const invalidSymbolRegExp = RegExp('^[a-zA-Z0-9!@#$%^&*~()]*$');
    if (!eightDigitRegExp.test(formValue)) {
      callback('Your password length must be 8 or more characters.');
    } else {
      if (!passwordStrengthRegExp.test(formValue)) {
        callback('Your password must have Uppercase letters, lowercase letters and numbers.');
        // callback('Must have at least 1 Upper case, 1 lower case and 1 digit');
      } else {
        // if (!invalidSymbolRegExp.test(formValue)) {
        //   callback('Must only have following symbol: ! @ # $ % ^ & * ~ ( )');
        // } else {
        callback();
        // }
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        await this._onSubmit(values.password);
      }
    });
  };

  _onSubmit = async (password) => {
    this.setState({ isLoading: true });
    // @ts-ignore
    const { location, doSetNewPassword } = this.props;
    const query = qs.parse(location.search);
    try {
      await doSetNewPassword({ code: query.oobCode, password });
      this.setState({ isLoading: false, isSuccess: true });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  private loginView() {
    const { form, history } = this.props;
    const { getFieldDecorator } = form;
    if (this.state.isError) {
      return (
        <>
          <div style={{ textAlign: 'center', marginBottom: '32px' }} className="anim-slide-left">
            <br />
            <br />
            <br />
            <Icon type="api" className="text-size-x6-large text-color-secondary pb-large" /> <br />
            <Text size="x3-large" weight="bold">
              Expired link
            </Text>
            <br /> <br />
            <Text size="x-large">
              Oops! This link has been expired.
              <br />
              Try resetting your password again.
            </Text>{' '}
            <br /> <br />
            <HyperlinkButton
              fontSize="x-large"
              onClick={() => history.push(`/login?redirect=${globalConfig.redirectUrl}`)}
            >
              Back to Home
            </HyperlinkButton>
          </div>
        </>
      );
    }

    if (this.state.isSuccess) {
      return (
        <div style={{ textAlign: 'center', marginBottom: '32px' }} className="anim-slide-left">
          <br />
          <Icon type="check-circle" className="text-color-forest-light" style={{ fontSize: '64px' }} /> <br /> <br />
          <Text size="x3-large" weight="bold" color={''}>
            Password successfully <b>updated</b>
          </Text>
          <br /> <br />
          <Text size="x-large">
            You can now login to <b>GoodHuman</b> with your new password.
          </Text>{' '}
          <br />
          <br />
          <HyperlinkButton
            fontSize="x-large"
            onClick={() => history.push(`/login?redirect=${globalConfig.redirectUrl}`)}
          >
            Click here to login
          </HyperlinkButton>
        </div>
      );
    }
    return (
      <div className="anim-slide-left">
        <div className="pb-x-small text-align-center">
          <Text size="x3-large" weight="bold">
            New GoodHuman password
          </Text>
        </div>
        <div className="pb-x-large text-align-center">
          <Text size="x-large">Please enter your new password below.</Text>{' '}
        </div>
        {/* NOTE Input box Section */}
        {/* TODO Validate email & password */}
        <Row type={'flex'} justify={'center'}>
          <Col md={24}>
            <Form.Item hasFeedback style={{ marginBottom: '0px' }}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    validator: this.validateEnteredPassword
                  }
                ]
              })(
                <Input.Password
                  size={'large'}
                  placeholder={'Password'}
                  prefix={<Icon type={'lock'} className="text-color-tertiary" />}
                />
              )}
            </Form.Item>
            <Form.Item hasFeedback style={{ marginBottom: '0px' }}>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(
                <Input.Password
                  onBlur={this.handleConfirmBlur}
                  size={'large'}
                  placeholder={'Confirm password'}
                  prefix={<Icon type={'lock'} className="text-color-tertiary" />}
                />
              )}
            </Form.Item>

            <div className="pt-x-large pb-x3-large rounded">
              <Text weight="bold">Your password must have :</Text> <br />
              <div>
                <div style={{ listStyle: 'none', padding: '0px' }}>
                  <FaLock className="text-color-tertiary mr-x-small" /> 8 or more characters
                </div>
                <div style={{ listStyle: 'none', padding: '0px' }}>
                  <FaLock className="text-color-tertiary mr-x-small" /> Uppercase and lowercase letters
                </div>
                <div style={{ listStyle: 'none', padding: '0px' }}>
                  <FaLock className="text-color-tertiary mr-x-small" /> At least one number
                </div>
              </div>
            </div>

            {/*/!* TODO Forgot your password functionality *!/*/}
            {/*<div className="text-align-right mt-small mb-large">*/}
            {/*  <Button type={'link'} className="pr-none" onClick={() => this.setState({ isForgetPassword: true })}>*/}
            {/*    Forgot your password?*/}
            {/*  </Button>*/}
            {/*</div>*/}

            <Button
              type={'primary'}
              block
              size={'large'}
              style={{
                marginBottom: '16px',
                backgroundColor: BlueprintColors.BLACK,
                borderColor: BlueprintColors.GRAY1
              }}
              onClick={this.handleSubmit}
            >
              Set new password
            </Button>

            <div style={{ marginTop: '24px', minHeight: '28px' }}>
              {this.state.isResetting && <ProgressBar animate={true} />}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    // @ts-ignore
    const { location, doCheckActionToken, doSignOutUsers } = this.props;
    await doSignOutUsers();
    // console.log(location);
    // console.log(qs.parse(location.search));
    const query = qs.parse(location.search);
    try {
      await doCheckActionToken({ code: query.oobCode });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false, isError: true });
    }
  }

  render() {
    return (
      <NotAuthorizedLayout>
        <CenteredLayout maxWidth={800} height="100%" className="flex-column justify-center">
          <div className="flex-row justify-center pt-medium pb-x2-large">
            <img src={logoBlack} alt="" style={{ width: '45%', height: '100%' }} />
          </div>
        </CenteredLayout>

        {/* Login Panel*/}
        <Row
          type={'flex'}
          justify={'center'}
          align={'middle'}
          style={{ height: '100%', backgroundColor: 'white', paddingBottom: '0px' }}
        >
          {/* NOTE Top Content Section */}
          <Col md={8} xs={20} style={{ backgroundColor: 'white' }}>
            {this.state.isLoading ? (
              <div className="text-align-center bordered p-large anim-fade-in">
                <div>Verifying, one minute...</div> <br />
                <ProgressBar animate={true} />
              </div>
            ) : (
              this.loginView()
            )}
          </Col>
        </Row>
      </NotAuthorizedLayout>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckActionToken: dispatch.authStore.doCheckActionToken,
  doSetNewPassword: dispatch.authStore.doSetNewPassword,
  doSignOutUsers: dispatch.authStore.doSignOutUsers
});

export default connect(
  null,
  mapDispatch
)(Form.create<ResetPasswordViewProps>()(ResetPasswordView));
