/* eslint-disable */
// ? ApiClient for accessing the API endpoints
import axios from 'axios';
import Utils from './Utils';
import globalConfig from '../variables/global-config';

// ! Edit from Jir : Added Firebase App directly here
import { getAuth } from 'firebase/auth';

import rootStore from '../stores/rematch/root-store';

const { isEmpty } = Utils;

// faux delay
// const asyncDelay = (ms) => new Promise((r) => setTimeout(r, ms));

// ? Debug mode will bypass
const debugMode = globalConfig.apiDebugMode;
const apiBaseUrl = globalConfig.apiBaseUrl;

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

const getClient = (baseUrl = apiBaseUrl) => {
  const options = {
    baseURL: baseUrl
  };

  // ? Use interceptors instead as the token will keep changing
  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    async (requestConfig) => {
      let interceptedConfig = { ...requestConfig };

      if (interceptedConfig.anonymousRequest) {
        // ! Anonymous Request - Some endpoints are anonymous, eg checkIfMobileExists. Don't append the Authorized Bearer header
        // console.log('anonymous request');
      } else {
        if (debugMode) {
          if (interceptedConfig.url.includes('?')) {
            interceptedConfig.url += '&debug=true&uid=' + globalConfig.uid;
          } else {
            interceptedConfig.url += '?debug=true&uid=' + globalConfig.uid;
          }

          const portalUser = rootStore.getState().authStore.portalUser;
          if (portalUser) {
            interceptedConfig.headers.serviceproviderid = portalUser.serviceProviderId;
          }
          return interceptedConfig;
        }

        // ! Authenticated Request - use Authorization Bearer token
        // console.log('authenticated request');

        // console.log('authenticating as normal mode');
        // ! Normal mode. Uses Firebase Auth Tokens

        // ! Edit by Jir : Get current User from Firebase
        const currentUser = await getAuth().currentUser;

        if (currentUser) {
          // console.log(`Authenticating as uid : ${currentUser.uid}`);

          // TODO note: This is assuming that getIdToken will cache and refresh as necessary. Need to test it out.
          const idToken = await currentUser.getIdToken();
          // const idToken = await currentUser.getIdToken(true);

          // console.log(idToken);

          interceptedConfig.headers.Authorization = `Bearer ${idToken}`;

          //! for development use only
          // TODO this looks dodgy... need to update this later
          const portalUser = rootStore.getState().authStore.portalUser;
          if (portalUser) {
            interceptedConfig.headers.serviceproviderid = portalUser.serviceProviderId;
          }
        } else {
          console.warn('Warning - no current user for Firebase. No Authorization Bearer token will be passed in');
        }
      }

      //// console.log(interceptedConfig);

      return interceptedConfig;
    },
    (requestError) => {
      // TODO : Send this over to a logging facility
      return Promise.reject(requestError);
    }
  );

  // ! Responses are wrapped with the following object format:
  //  *    {
  //  *      status   : http status code
  //  *      data     : actual data
  //  *      meta     : meta response
  //  *      message  : error message (only for errors)
  //  *    }

  client.interceptors.response.use(
    (response) => {
      const { status, data: rawData } = response;
      let data, meta;
      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else {
        data = rawData.data;
        meta = rawData.meta;
      }

      return { status, data, meta };
    },
    (error) => {
      // Handle exceptions/invalid logs here
      const { status, data: rawData } = error.response;

      let data, meta;

      // Prevent undefined errors later...
      if (isEmpty(rawData)) {
        data = {};
        meta = {};
      } else {
        data = rawData.data;
        meta = rawData.meta;
      }

      return Promise.reject({ status, data, meta, message: error.message });
    }
  );

  return client;
};

class ApiClient {
  client = {};

  constructor(baseUrl = apiBaseUrl) {
    this.client = getClient(baseUrl);
  }

  download(url, conf = {}) {
    return this.client
      .pipe(
        url,
        conf
      )
      .then();
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        console.log(error);
        throw error;
      });
  }

  // ! Anonymous request
  getAsAnonymous(url, conf = {}) {
    return this.client
      .get(url, { ...conf, anonymousRequest: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        Promise.reject(error);
      });
  }

  delete(url, conf = {}) {
    // previously was delete(url, conf)
    return this.client
      .delete(url, { data: conf })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        console.log(error);
        throw error;
      });
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        Promise.reject(error);
      });
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        Promise.reject(error);
      });
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        console.log(error);
        throw error;
      });
  }

  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        console.log(error);
        throw error;
      });
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        console.log(`request failed for ${url}`);
        Promise.reject(error);
      });
  }
}

const defaultClient = new ApiClient();

// ? Default imports an instantiated client.
export default defaultClient;

// ? Alternatively, construct it yourself if there's another url that you need to access.
// Example : const client = new ApiClient('baseUrl');

export { ApiClient };
