// GH Dev
const globalConfig = {
  apiBaseUrl: 'https://australia-southeast1-gh-dev-718d6.cloudfunctions.net',
  apiDebugMode: false,
  uid: '6WG1e4OG9xcgTDurWgvvJjsAaaT2',
  googleMapApiKey: 'AIzaSyCtoN5Lcy8yHrrY5UpPOLjTFQjENdf7qKI',
  pubnubSubscribeKey: 'sub-c-75282fce-4dee-11ea-814d-0ecb550e9de2',
  redirectUrl: 'https://team-dev.goodhuman.me'
};

export default globalConfig;
