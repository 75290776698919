/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { Button, Icon } from 'antd';
import { PORTAL_COLORS } from '../../interfaces/common-ui-types';

import { isEmpty } from 'lodash';

const defaultButtonColor = 'green-dark';
const defaultShape = 'default';
const defaultSize = 'default';

interface PrimaryButtonProps {
  children?: ReactNode;
  size?: 'small' | 'default' | 'large' | string;
  color?: PORTAL_COLORS;
  shape?: 'circle' | 'round' | 'circle-outline' | 'default' | string;
  paddingSize?: 'none' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  loading?: boolean;
  disabled?: boolean;
  icon?: string;
  onClick?: any;
  className?: string;
  iconPosition?: 'left' | 'right';
}

class SecondaryButton extends PureComponent<PrimaryButtonProps, any> {
  render() {
    const {
      children,
      size = defaultSize,
      color = defaultButtonColor,
      shape = defaultShape,
      loading = false,
      paddingSize,
      icon,
      iconPosition = 'left',
      disabled = false,
      className = '',
      onClick = () => console.log('button handler not implemented')
    } = this.props;

    let finalClassName = ' ';
    let fontSize = '16px';
    let shapeProps: any = { shape };
    let disabledProps = { disabled };

    // Color
    if (disabled) {
      // Disabled; color defaults to grey
      finalClassName += 'bg-white border-color-tertiary text-color-tertiary ';
    } else {
      finalClassName += 'bg-white text-color-' + color + ' border-' + color + ' hover-border-' + color + ' ';
    }

    // Size
    if (size === 'default') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'medium' : paddingSize} `;
      fontSize = '16px';
    } else if (size === 'large') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'large' : paddingSize} `;
      fontSize = '17px';
    } else if (size === 'small') {
      finalClassName += `ph-${isEmpty(paddingSize) ? 'small' : paddingSize} `;
      fontSize = '15px';
    }

    // Button Shape
    if (shape === 'default') {
      // Remove shape properties
      shapeProps = {};
    }

    // Loading; if it's loading, disable buttons and change the color as well
    if (loading) {
      disabledProps = { disabled: true };
      finalClassName += 'dimmer text-color-' + color + ' cursor-wait ';
    } else {
      finalClassName += 'dim hover-undim ';
    }

    finalClassName += className;

    return (
      <Button
        type="default"
        className={finalClassName}
        style={{ fontSize }}
        {...shapeProps}
        size={size}
        {...disabledProps}
        onClick={onClick}
      >
        {!loading && !isEmpty(icon) && iconPosition === 'left' && <Icon type={icon} />}
        {loading && iconPosition === 'left' && <Icon type="loading" />}
        {children}
        {!loading && !isEmpty(icon) && iconPosition === 'right' && <Icon type={icon} />}
        {loading && iconPosition === 'right' && <Icon type="loading" />}
        {/*{loading && <Icon type="loading" />}*/}
      </Button>
    );
  }
}

export default SecondaryButton;
