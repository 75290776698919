import moment, { Moment } from 'moment';
import globalConfig from 'variables/global-config';

import jwt from 'jwt-simple';
import * as uuid from 'uuid';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

class CommonUtils {
  static formatStatusString(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'ACCEPTED':
        return 'Accepted';
      case 'CONFIRMED':
        return 'Confirmed';
      case 'INPROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'APPROVED':
        return 'Approved';
      case 'CLOSED':
        return 'Achieved';
      case 'CANCELLED':
        return 'Cancelled';
      case 'REJECTED':
        return 'Rejected';
      case 'READY_FOR_BILLING':
        return 'Ready for billing';
      case 'REQUIRES_APPROVAL':
        return 'Requires Approval';
      case 'BILLING_FAILED':
        return 'Finance removed';
      case 'BILLED':
        return 'Billed';
      case 'CANCEL_SHORT_NOTICE':
        return 'Cancelled (Short Notice)';
      case 'CUSTOMER_CANCELLED':
        return 'Customer Cancelled';
      case 'BUSINESS_CANCELLED':
        return 'Business Cancelled';

      default:
        return 'N/A';
    }
  }

  static mapWorkerStatus(status: string): string {
    switch (status) {
      case 'FINDING':
        return 'Finding Worker';
      case 'REJECTED':
        return 'Worker Rejected';
      case 'FOUND':
        return 'Worker Confirmed';
      default:
        return 'N/A';
    }
  }

  static cancelReasons = {
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4'
  };

  static mapCancelReason(status: string): any {
    return CommonUtils.cancelReasons[status];
  }

  static formatPrice(price: number): string {
    // return price ? parseFloat(price.toString()).toFixed(2) : '0.00';
    return price ? formatter.format(price) : '$0.00';
  }

  static mapBookingStatusDescription(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'You have a new booking Request! You can either accept or reject this request. If you accept this request you will need to assign a worker to the booking.';
      case 'REJECTED':
        return 'You have rejected this booking Request.';
      case 'ACCEPTED':
        return "This booking has been accepted! You will now need to assign a worker to the booking in order to fully confirm it. The worker you assign to this booking will have to accept the booking. Don't worry, we will only inform the customer the worker that has been assigned to them after the worker accepts.";
      case 'CONFIRMED':
        return 'This booking has been fully confirmed! We’ll let you know when the worker checks in, and starts delivering the service.';
      case 'INPROGRESS':
        return 'This booking is currently in progress! The assigned support worker has checked in and is now assisting the customer.';
      case 'COMPLETED':
        return "This booking has been successfully completed! Please review today's notes and sign off on the completion of the booking.";
      case 'CANCEL_SHORT_NOTICE':
        return 'The customer has cancelled the booking. As they have cancelled during the specified short notice period, you have the option of charging them your provider cancellation fee. Please review their reason for cancellation and choose whether you wish to charge them or not.';
      case 'CANCELLED':
        return 'You have cancelled this booking Request.';
      default:
        return 'N/A';
    }
  }

  static formatDuration(startDateTime: Moment, endDateTime: Moment) {
    const duration = moment.duration(endDateTime.diff(startDateTime));

    const durationFormatter: any = {};

    if (duration.years() > 0) {
      durationFormatter.years = {
        value: duration.years(),
        label: 'year(s)'
      };
    }

    if (duration.months() > 0) {
      durationFormatter.months = {
        value: duration.months(),
        label: 'month(s)'
      };
    }

    if (duration.days() > 0) {
      durationFormatter.days = {
        value: duration.days(),
        label: 'day(s)'
      };
    }

    if (duration.hours() > 0) {
      durationFormatter.hours = {
        value: duration.hours(),
        label: 'hour(s)'
      };
    }

    if (duration.minutes() > 0) {
      durationFormatter.minutes = {
        value: duration.minutes(),
        label: 'minute(s)'
      };
    }
    return durationFormatter;
  }

  // Address/location Utils
  static formatFullAddress(address: any) {
    // const streetAddress = address.streetAddress1 + address.streetAddress2 ? ' ' + address.streetAddress2 : '';
    const streetAddress = `${address.streetAddress1}${address.streetAddress2 ? ' ' + address.streetAddress2 : ''}`;
    const suburbStatePostcode = `${address.locality ? address.locality + ' ' : ''}${
      address.state ? address.state + ' ' : ''
    }${address.postcode ? address.postcode : ''}`;
    // ! format full address by adding space and comma when data are present
    return `${streetAddress ? streetAddress + ',\n' : ''}${suburbStatePostcode ? suburbStatePostcode + ',\n' : ''}${
      address.country
    }`;
  }

  static getLatLongByAddress = async (fulladdress) => {
    const response = await fetch(
      'https://maps.googleapis.com/maps/api/geocode/json?key=' +
        globalConfig.googleMapApiKey +
        '&address=' +
        fulladdress
    );
    const responseJson = await response.json();

    return responseJson.results[0].geometry.location;
  };

  static formatCeilingDateTime(date, duration = moment.duration(5, 'minutes')) {
    return moment(Math['ceil'](+date / +duration) * +duration);
  }

  static getZendeskRedirectUrl(portalUser, redirectUrl: string) {
    const shared_key = 'qB0IGOrMZyaYZoQnUpR0FPrn1lMtqvmmjHhWmqoZM2DndsQq';

    let tags = [];
    if (portalUser.isPortalUser) {
      tags.push('Admins');
    }
    if (portalUser.isUser) {
      tags.push('Consumers');
    }
    if (portalUser.isWorker) {
      tags.push('Workers');
    }

    const payload = {
      iat: new Date().getTime() / 1000,
      jti: uuid.v4(),
      name: portalUser.additionalInfo.firstName + ' ' + portalUser.additionalInfo.lastName,
      email: portalUser.additionalInfo.userId + '@goodhuman.me',
      tags
    };

    // encode
    const token = jwt.encode(payload, shared_key);
    let redirect = 'https://goodhuman.zendesk.com/access/jwt?jwt=' + token;

    redirect += `&return_to=${redirectUrl}`;

    return redirect;
  }
}

export default CommonUtils;
