/* eslint-disable */
import React, { Component } from 'react';
import { Typography, Card, Divider, Menu, Icon, Button } from 'antd';

const { Text } = Typography;

export default class NotificationMenu extends Component {
  render() {
    return (
      <Card style={{ marginTop: '-5px', marginRight: '0px' }} bodyStyle={{ padding: '0px' }}>
        <div style={{ padding: '8px 16px' }}>
          <Text strong>You have 50 unread notifications</Text>
        </div>
        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

        <div style={{ padding: '8px 16px' }}>
          <Text>Jonathan has viewed your profile</Text>
          <br />
          <Text>Jonathan has viewed your profile</Text>
          <br />
          <Text>Jonathan has viewed your profile</Text>
          <br />
          <Text>Jonathan has viewed your profile</Text>
          <br />
          <Text>Jonathan has viewed your profile</Text>
          <br />
        </div>

        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

        <div>
          <Button type={'link'}>View All Notifications</Button>
        </div>
      </Card>
    );
  }
}
