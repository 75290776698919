/* eslint-disable */
// AppContainer consists of the main layout, with sider and top navigation menu.
// Nav menus are found in components/navigation.

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Avatar, Badge, Col, Dropdown, Icon, Layout, Row } from 'antd';

import { withRouter } from 'react-router-dom';

import styled from 'styled-components';

import { Colors as BlueprintColors } from '@blueprintjs/core';

import NavLogo from 'common-components/navigation/NavLogo';
import SideNavMenu from 'common-components/navigation/SideNavMenu';
import ProfileMenu from 'common-components/navigation/ProfileMenu';
import NotificationMenu from 'common-components/navigation/NotificationMenu';

import { Text } from 'common-components/typography';

import { capitalize } from 'lodash';

import Animate from 'rc-animate';

import { logoBlack } from 'assets/logo';

const { Header, Content, Footer, Sider } = Layout;

const HeaderItem = styled.span`
  height: 100%;
  background-color: transparent;
  padding: 0 16px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${BlueprintColors.LIGHT_GRAY4};
  }
`;

const notificationMenu = <NotificationMenu />;

class AppContainer extends Component<any, any> {
  static propTypes = {
    disableBreadcrumbs: PropTypes.bool,
    containerStyle: PropTypes.object
  };
  q;

  state = {
    sideMenuCollapsed: true,
    titleDisplay: 'dashboard'
  };

  _toggleSide = () => {
    this.setState({ sideMenuCollapsed: !this.state.sideMenuCollapsed });
  };

  _setTitle = () => {
    let title = this.props.location.pathname.replace('/', '').replace('-', ' ');
    document.title = `${capitalize(title)} | GoodHuman`;

    if (this.state.titleDisplay !== title) {
      this.setState({ titleDisplay: title });
    }
  };

  componentDidMount(): void {
    this._setTitle();
  }

  private _profileMenu = () => {
    const { history, portalUser } = this.props;
    return <ProfileMenu history={history} portalUser={portalUser} />;
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.location !== this.props.location) {
      this._setTitle();
    }
  }

  render() {
    const { messagingBadgeCount, badgeCount, onClickBadge, portalUser, onClickMessage } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/* NOTE Side Menu */}
        {/* TODO - Animate sider */}

        <Animate transitionName="fade" className="bg-black">
          <div
            style={{
              width: this.state.sideMenuCollapsed ? '0px' : '200px',
              opacity: this.state.sideMenuCollapsed ? 0 : 1,
              transition: '0.3s width, 0.2s opacity',
              overflow: 'hidden',
              animationTimingFunction: 'ease-in-out'
            }}
            className="bg-black"
          >
            <Sider
              collapsible
              collapsed={this.state.sideMenuCollapsed}
              onCollapse={this._toggleSide}
              style={{ width: this.state.sideMenuCollapsed ? '0' : '80px' }}
            >
              {/*<Sider collapsed={this.state.sideMenuCollapsed} onCollapse={this._toggleSide} style={{ display: 'none' }}>*/}
              {/* NOTE GoodHuman Logo */}
              <NavLogo sideMenuCollapsed={this.state.sideMenuCollapsed} />

              {/* Menu */}
              <SideNavMenu />
            </Sider>
          </div>
        </Animate>

        {/* Main Layout */}
        <Layout>
          {/* Header Navigation */}
          <Header
            style={{
              backgroundColor: BlueprintColors.WHITE
              // background: `linear-gradient(to right,#13bda6,#4fcdbc)`
            }}
          >
            <Row type={'flex'} justify={'space-between'} className={'align-center'}>
              {/* Menu Trigger */}
              <Col span={6}>
                <HeaderItem onClick={this._toggleSide}>
                  <Icon
                    className="trigger"
                    type={this.state.sideMenuCollapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={() => {}}
                    style={{ paddingLeft: '8px', paddingRight: '8px' }}
                  />
                </HeaderItem>
                {/*<Text style={{ paddingLeft: '16px', fontSize: '16px', fontWeight: 500, textTransform: 'uppercase' }}>*/}
                {/*  /!*{this.state.titleDisplay}*!/*/}
                {/*  GoodHuman Service Portal*/}
                {/*</Text>*/}
                <img src={logoBlack} alt="" style={{ height: '17px' }} className="ml-medium" />
              </Col>

              <Col span={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                {/* Messaging */}
                <span className={'cursor-pointer mr-large'} style={{ paddingRight: '10px' }} onClick={onClickMessage}>
                  <Badge count={messagingBadgeCount} offset={[5, 5]}>
                    <Icon type={'mail'} style={{ fontSize: '22px' }} className="text-color-secondary" />
                  </Badge>
                </span>

                {/* Notifications */}
                <span className={'cursor-pointer'} style={{ paddingRight: '10px' }} onClick={onClickBadge}>
                  <Badge count={badgeCount} offset={[5, 5]}>
                    <Icon type={'bell'} style={{ fontSize: '22px' }} className="text-color-secondary" />
                  </Badge>
                </span>
                {/* User Avatar */}
                {/* TODO : Get the actual user Avatar */}
                <Dropdown overlay={this._profileMenu()} placement={'bottomCenter'} trigger={['click']}>
                  <HeaderItem>
                    <Avatar icon={'user'} shape={'circle'} src={portalUser.attachmentUrl} />
                    <Text style={{ paddingLeft: '8px' }}>{portalUser.firstName + ' ' + portalUser.lastName}</Text>
                    <Icon type={'down'} style={{ paddingLeft: '16px', fontSize: '10px' }} />
                  </HeaderItem>
                </Dropdown>
              </Col>
            </Row>
          </Header>

          {/* NOTE : Content Section */}
          <Content>
            {/* Breadcrumb */}
            {/* set disableBreadcrumbs to remove this */}
            {/*{disableBreadcrumbs ? (*/}
            {/*  <></>*/}
            {/*) : (*/}
            {/*  <div style={{ backgroundColor: 'transparent', padding: '20px 40px' }}>*/}
            {/*    <Breadcrumb>*/}
            {/*      <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
            {/*      <Breadcrumb.Item>Services</Breadcrumb.Item>*/}
            {/*      <Breadcrumb.Item>View Services</Breadcrumb.Item>*/}
            {/*    </Breadcrumb>*/}
            {/*    /!*<Divider />*!/*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*Main content area */}
            {this.props.children}
          </Content>

          {/* NOTE : Footer Section */}
          {/*<Footer style={{ backgroundColor: 'white', padding: '0px', margin: '0px', height: '47px' }}>*/}
          {/*  <Row>*/}
          {/*    <Col span={24} style={{ textAlign: 'right', paddingRight: '8px' }}>*/}
          {/*      /!*<Divider />*!/*/}
          {/*      <div style={{ padding: '12 px' }}>*/}
          {/*        <Text>2019 © GoodHuman copyrights reserved.</Text>*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Footer>*/}
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(AppContainer);
