import React, { PureComponent } from 'react';

import { Text } from 'common-components/typography';

interface IFieldValueTextProps {
  text: string;
}

class FieldValueText extends PureComponent<IFieldValueTextProps, any> {
  render() {
    const { text } = this.props;

    return (
      <div>
        <Text size="x-large">{text}</Text>
      </div>
    );
  }
}

export default FieldValueText;
